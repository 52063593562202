export const getErrorMessageMetamask = (e) => {
  if (e) {
    if (JSON.stringify(e).split('message":"')[1]) {
      return (
        JSON.stringify(e).split('message":"')[1].split('"')[0]
      );
    }
    if (JSON.stringify(e).split('reason":"')[1]) {
      return (
        JSON.stringify(e).split('reason":"')[1].split('"')[0]
      );
    }
  }
  return (
    e
  );
};