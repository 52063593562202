import { useEthers } from "@usedapp/core/dist/esm/src";
import { Contract, ethers } from "ethers";
import { useEffect, useState } from "react";
import { getErrorMessageMetamask } from "../helpers/utils";
import regularDollarsABI from '../web3/abi/regularDollarsABI.json';

const json = {
  1: {
    "name": "Pickle Jam",
    "description": "A pickle celebration.", 
    "external_url": "https://picklepeople.io", 
    "image": "https://static.mustardlabs.io/pickles/editions/img/1.gif", 
    "price": "69", 
    "soldOut": "true",
    "attributes": [
      {
        "trait_type": "Vibe", 
        "value": "Strong"
      },  
      {
        "trait_type": "Dillary", 
        "value": "Maximum"
      }, 
      {
        "trait_type": "Freshness", 
        "value": "Very Fresh"
      }
    ]
  },
  2: {
    "name": "French Pickle",
    "description": "The pickle that started it all, the OG", 
    "external_url": "https://picklepeople.io", 
    "image": "https://static.mustardlabs.io/pickles/editions/img/2.png", 
    "price": "5000", 
    "attributes": [
      {
        "trait_type": "Ambiance", 
        "value": "énorme"
      },  
      {
        "trait_type": "Longueur de Pipe", 
        "value": "Long"
      }, 
      {
        "trait_type": "Fraîcheur", 
        "value": "OG"
      }
    ]
  },
  3: {
    "name": "Lurkin' Gherkin",
    "description": "Just some pickles...", 
    "external_url": "https://picklepeople.io/", 
    "image": "https://static.mustardlabs.io/pickles/editions/img/3.gif", 
    "price": "0", 
    "attributes": [
      {
        "trait_type": "Patience Skill lvl", 
        "value": "100"
      }
    ]
  },
  4: {
    "name": "Texas Pickle",
    "description": "This jar ain't big enough for the two of us...", 
    "external_url": "https://picklepeople.io/", 
    "image": "https://static.mustardlabs.io/pickles/editions/img/4.png", 
    "price": "420",
    "soldOut": "true",
    "attributes": [
      {
        "trait_type": "Catch Phrase", 
        "value": "Howdy"
      },
      {
        "trait_type": "Glasses", 
        "value": "Pick Vipers"
      }, 
      {
        "trait_type": "Hat", 
        "value": "Big"
      }
    ]
  }
}

const freeMintAddress = '0x777450d62cd0d651906ed894e490f2888e9eef59';
const freeMintABI = '[{"inputs":[],"name":"mintFree","outputs":[],"stateMutability":"nonpayable","type":"function"}]';

const Home = () => {
  const {account, library} = useEthers();
  const [pickleId, setPickleId] = useState(3);
  const [maxPickles, setMaxPickles] = useState(4);
  const [pickleData, setPickleData] = useState(null);

  const handleMint = () => {
    if (!account ||!library) {
      alert('Link your wallet')
      return;
    }
    if (pickleId === 3) {
      const contractInstance = new Contract(freeMintAddress,freeMintABI, library.getSigner());
      contractInstance.mintFree();
      return
    }
    const price= ethers.utils.parseUnits(pickleData.price, 18);
    const pickleContract = "0x8b79B97bf6b88Ec7D145E44d0fC2CDB37E7A5a72";
    const regularDollars = "0x78b5C6149C87c82EDCffC73C230395abbc56DdD5";
    const myData = ethers.utils.defaultAbiCoder.encode(['uint256' ],[ pickleId ])
    const contractInstance = new Contract(regularDollars,regularDollarsABI, library.getSigner());
    contractInstance.transferAndCall(pickleContract,price, myData).catch((e) => alert(getErrorMessageMetamask(e)));
  }

  const getPickleData = (id) => {
    setPickleData(json[id]);
  }

  useEffect(() => {
    getPickleData(pickleId)
  }, [pickleId])

  return (
    <div className="text-secondary" style={{
      position: "absolute",
      top: "50%",
      left:"50%",
      transform: "translate(-50%,-50%)",
    }}>
      {pickleData && <img src={pickleData.image} alt="pickle!" style={{width: "100%", maxWidth: "300px"}} />}
      <h2 style={{textAlign: "center"}}>Mint ERC1155 Pickle!</h2>
      <div style={{display: "flex", gap: "10px", justifyContent: "center", flexWrap: "wrap"}}>
      <label htmlFor="pickleId">
        Pickle Id{" "}
        <select value={pickleId} onChange={(e) => setPickleId(Number(e.target.value))}>
          {Array(maxPickles).fill(0).map((n,index) => (
            <option default={index+1 === pickleId} value={index+1}>{index+1}</option>
          ))}
        </select>
      </label>
        {pickleData && <>Price: {pickleData.price} REG</>}
      </div>
      <div><button disabled={pickleData?.soldOut}className="submitDraw text-secondary" onClick={() => handleMint()}>{pickleData?.soldOut ? "Sold out": "Mint"}</button></div>

    </div>
  )
}

export default Home;
