import './App.css';
import Header from './components/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';

function App() {

  return (
    <BrowserRouter>
        <div className="App">
        <Header />
    <Routes>
      <Route path="/" element={<Home />}/>
    </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
