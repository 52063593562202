import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { useEthers, useLookupAddress } from "@usedapp/core";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useEffect, useState } from "react";
import Web3Modal from 'web3modal';
import { getMyInvites } from "../firestore/firestore";
import InvitationModal from "./InvitationModal";

export const sort0xAddress = (address) => {
  if (address.length < 16) return address;

  return `${address.slice(0, 2)}...${address.slice(
    address.length - 4,
    address.length
  )}`;
};

const ConnectWallet = () => {
  const { activateBrowserWallet, account, activate } = useEthers();
  const { ens } = useLookupAddress(account)
  const connectWallet = async () => {
    if (window?.ethereum?.isMetaMask) {
      await activateBrowserWallet();
    } else {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: '39971fe2ad00481bb2b331d3fed8366d',
          },
        },
        coinbasewallet: {
          package: CoinbaseWalletSDK,
          options: {
            appName: 'Pickles',
            infuraId: '39971fe2ad00481bb2b331d3fed8366d',
          },
        },
      };
      const web3Modal = new Web3Modal({
        network: 'mainnet', // optional
        cacheProvider: true, // optional
        providerOptions, // required
      });

      const provider = await web3Modal.connect();
      await activate(provider);
    }
  }

  const [invites, setInvites] = useState(0);
  const [showInvitatoinModal, setShowInvitationModal] = useState(false);
  if (account) {

    return (
      <div className="hideMobile">
      <button className="connectWallet reversed">
      {sort0xAddress(ens ?? account)}
    </button>
    </div>
    )
  }
 return (
  <button id="connectWalletButton" onClick={() => connectWallet()} className="connectWallet hideMobile">
    Connect Wallet
  </button>
 )
}

export default ConnectWallet;
